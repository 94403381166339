@import "/src/assets/fonts/fonts.css";

.App {
    display: flex;
    min-width: 1680px;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    position: relative;
    font-family: "Noto Sans", sans-serif;
}

.App-content {
    width: 100%;
    min-width: 1680px;
    display: block;
    margin: 0 auto;
    flex-grow: 1;
    position: relative;
}

html {
    font-size: 16px;
}